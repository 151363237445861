#home {
  display: flex;
  flex-direction: column;
  align-items: center;

  .profile {
    width: 110px;
    height: 110px;
    margin-top: 2rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .typingAnimation {
    color: #fff;
    font-size: 2rem;
    margin-top: 5rem;
  }
  .profile_text {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    h3 {
      color: #fff;
      font-size: 2rem;
      @media screen and (max-width: 500px) {
        font-size: 1.5rem;
      }
      span {
        color: #5cbbbb;
      }
    }
    .job {
      color: #fff;
      opacity: 0.5;
      font-size: 0.85rem;
    }
    .text {
      margin-top: 2rem;
      color: #fff;
      text-align: center;
      font-size: 5rem;
      line-height: 1.3;

      @media screen and (max-width: 800px) {
        font-size: 3.5rem;
        margin-top: 2rem;
      }
      @media screen and(max-width: 530px) {
        font-size: 2.5rem;
      }
    }
    a {
      margin-top: 3rem;
      text-decoration: none;
      color: #000;
      background: #fff;
      padding: 0.65rem 1rem;
      border-radius: 10px;
      font-size: 0.85rem;
      text-transform: capitalize;
    }
    div {
      position: absolute;
      background: rgba(255, 255, 255, 0.15);
      color: #fff;
      padding: 0.35rem 1rem;
      border-radius: 25px;
      font-size: 0.85rem;
      cursor: pointer;
    }
    @media screen and (max-width: 800px) {
      div {
        display: none;
      }
    }
    .web {
      right: -1rem;
      top: 3.5rem;
    }
    .ui {
      top: 2rem;
      left: 2rem;
    }
    .freelance {
      bottom: 2rem;
      left: 0;
    }
  }
}
